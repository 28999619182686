import {createSelector} from "reselect";
import {RootState} from "../../app/store";

const selectTrainingReducer = (state: RootState) => state.training;

export const selectCurrentlyTrainedWords = createSelector(
    [selectTrainingReducer],
    (training) => training.currentlyTrainedWords,
)

export const selectAudioArray = createSelector(
    [selectTrainingReducer],
    (training) => training.audioArray,
)

export const selectPhraseConstructionArray = createSelector(
    [selectTrainingReducer],
    (training) => training.phraseConstructionArray,
)

export const selectCompeteEmptySpacesArray = createSelector(
    [selectTrainingReducer],
    (training) => training.completeEmptySpacesArray,
)

export const selectDeclensionTrainerArray = createSelector(
	[selectTrainingReducer],
	(training) => training.declensionTrainerArray,
)

export const selectFinalTrainingSequence = createSelector(
    [selectTrainingReducer],
    (training) => training.finalTrainingSequence,
)

export const selectFinalSequenceIndex = createSelector(
    [selectTrainingReducer],
    (training) => training.finalSequenceIndex,
)

export const selectHintsCount = createSelector(
    [selectTrainingReducer],
    (training) => training.hintsCount,
)

export const selectMistakesCount = createSelector(
    [selectTrainingReducer],
    (training) => training.mistakesCount,
)

export const selectTotalWordsOnTraining = createSelector(
    [selectTrainingReducer],
    (training) => training.totalWordsOnTraining,
)

export const selectTotalExercisesCount = createSelector(
    [selectTrainingReducer],
    (training) => training.totalExercisesCount,
)
