import {configureStore, Middleware} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import {rootReducer} from "./root-reducer";
import {thunk} from "redux-thunk";

export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['training']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger: Middleware<{}, RootState> = store => next => action => {
    if (!action) return;

    // @ts-ignore
    console.group(action.type)
    console.info('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    console.groupEnd()
    return result
};

const middlewares: Middleware[] = [
    thunk, logger
];

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(middlewares),
});

export const persistor = persistStore(store);
