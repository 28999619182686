import {createSlice} from "@reduxjs/toolkit";
import {PartOfSpeech, UserVocabulary} from "../training/trainingTypes";
import {Language} from "../languageSlice";

export const ADD_VOCABULARY_BUTTON: VocabularyType = {
    id: 5,
    learningLanguage: null,
    nativeLanguage: null,
    numberOfWords: -1,
    createdDate: 'a long time ago',
    loading: false,
    vocabularyGroupList: []
}

export type WordListWord = {
    tempWordId?: string;
    userVocabularyId?: number;
    vocabularyId?: number;
    vocabularyGroupId?: number;
    wordTranslationId?: number;
    wordFromId?: number;
    wordToId?: number;
    wordFrom: string;
    wordTo: string;
    soundUrl?: string;
    loading?: boolean;
    createdAt?: number;
}

export type VocabularyGroup = {
    groupId: number;
    name?: string;
    wordsNumber?: number;
    imageUrl?: string;
    predefined?: boolean;
    vocabulary?: VocabularyType;
}

export type VocabularyType = {
    id: number,
    learningLanguage: Language | null,
    nativeLanguage: Language | null,
    numberOfWords: number | string,
    createdDate: string,
    loading: boolean,
    vocabularyGroupList: VocabularyGroup[],
}

export type TranslationJson = {
    translation: string,
    pos: PartOfSpeech,
}

export type AddWordType = {
    tempWordId: string;
    word: string;
    translation: string;
    loading: boolean;
    vocabularyId: number;
    vocabularyGroupId: number;
}

const addNewWord = (currentWords: WordListWord[], addWordType: AddWordType): WordListWord[] => {
    return [...currentWords, getDefaultWordListWord(addWordType)];
}

const addAlreadyCreatedWord = (currentWords: WordListWord[], wordList: WordListWord, vocabularyId: number, vocabularyGroupId: number): WordListWord[] => {
    return [...currentWords, {...wordList, vocabularyId, vocabularyGroupId, loading: true}];
}

const getDefaultWordListWord = ({
                                    tempWordId,
                                    word,
                                    translation,
                                    vocabularyGroupId,
                                    vocabularyId
                                }: AddWordType): WordListWord => {
    return {
        tempWordId,
        wordFrom: word,
        wordTo: translation,
        wordTranslationId: 0,
        vocabularyGroupId,
        vocabularyId,
        wordFromId: 0,
        wordToId: 0,
        userVocabularyId: 0,
        soundUrl: "",
        loading: true,
        createdAt: Date.now()
    }
}

const removeWords = (currentWords: WordListWord[], wordToRemove: WordListWord) => {
    const result = currentWords.filter((word) => word.userVocabularyId !== wordToRemove.userVocabularyId);
    return result;
}

const addVocabulary = (currentVocabularies: Array<VocabularyType>, vocabulary: VocabularyType) => {
    const last = currentVocabularies.pop()!;
    currentVocabularies.push(vocabulary)
    currentVocabularies.push(last)
    return currentVocabularies;
}

const filterVocabularies = (vocabularies: VocabularyType[]): VocabularyType[] => {
    vocabularies.sort((a, b) => {
        if (a.numberOfWords < b.numberOfWords) return 1;
        else if (a.numberOfWords > b.numberOfWords) return -1;
        return 0;
    });
    if (vocabularies.includes(ADD_VOCABULARY_BUTTON)) return vocabularies;
    vocabularies.push(ADD_VOCABULARY_BUTTON);
    return vocabularies;
}

const replaceTempIdWord = (userVocabulary: WordListWord, allWords: WordListWord[]): WordListWord[] => {
    const newArr: WordListWord[] = [];

    for (let word of allWords) {
        if ((word.tempWordId === userVocabulary.tempWordId
            || (word.wordFrom === userVocabulary.wordFrom && word.wordTo === userVocabulary.wordTo)) && word.loading) continue;
        else newArr.push(word);
    }
    newArr.push(userVocabulary);

    return newArr;
}

const removeVocabularyFromState = (vocabularyId: number, vocabularies: VocabularyType[]): VocabularyType[] => {
    return vocabularies.filter((vocabulary) => vocabulary.id !== vocabularyId);
}


export type VocabularyState = {
    readonly currentlyInspectedWord: UserVocabulary | null,
    readonly currentlyInspectedWordGender: string;
    readonly currentVocabularyWords: WordListWord[],
    readonly allWords: WordListWord[],
    readonly selected: VocabularyType,
    readonly vocabularies: VocabularyType[],
    readonly suggestedVocabularyGroups: VocabularyGroup[],
    readonly vocabularyGroup: VocabularyGroup
}

export const VOCABULARY_INITIAL_STATE: VocabularyState = {
    currentlyInspectedWord: {
        createdAt: Date.now()
    },
    currentlyInspectedWordGender: "",
    currentVocabularyWords: [],
    allWords: [],
    selected: {
        id: 0,
        createdDate: '',
        learningLanguage: null,
        nativeLanguage: null,
        numberOfWords: 0,
        loading: false,
        vocabularyGroupList: []
    },
    vocabularies: [],
    suggestedVocabularyGroups: [],
    vocabularyGroup: {
        groupId: 0
    }
}

function addVocabularyGroup(vocabularyGroup: VocabularyGroup, vocabulary: VocabularyType) {
    const temp = {...vocabulary};
    temp.vocabularyGroupList = [...(temp.vocabularyGroupList ?? []), vocabularyGroup];
    return temp;
}

function removeVocabularyGroup(vocabularyGroup: VocabularyGroup, vocabulary: VocabularyType) {
    const temp = {...vocabulary};
    temp.vocabularyGroupList = vocabulary.vocabularyGroupList.filter((group) => +group.groupId !== +vocabularyGroup.groupId);
    return temp;
}

function removeFromSuggestedVocabularyGroups(suggestedVocabularyGroups: VocabularyGroup[], payload: VocabularyGroup) {
    return suggestedVocabularyGroups.filter(e => e.groupId !== payload.groupId);
}

function setCurrentWordsWithTempId(payload: WordListWord[], currentVocabularyWords: WordListWord[]): WordListWord[] {
    if (!currentVocabularyWords) return payload;
    const finalArray = [];

    for (let word of currentVocabularyWords) {
        if (!word?.tempWordId) continue;

        finalArray.push(word)
    }
    const result = [...payload, ...finalArray];
    const visited = new Set<string>();

    return result.filter(e => {
        if (visited.has(e.wordFrom + "," + e.wordTo)) return false;
        visited.add(e.wordFrom + "," + e.wordTo);
        return true;
    });
}

function setCurrentWordsWordWithId(previousId: number, newUserVocabulary: WordListWord, currentVocabularyWords: WordListWord[]): WordListWord[] {
    const temp = [];
    for (let word of currentVocabularyWords) {
        if (word.userVocabularyId === previousId) {
            temp.push(newUserVocabulary);
        } else temp.push(word);
    }
    return temp;
}

function setWordLoadingById(currentWords: WordListWord[], userVocabularyId: number) {
    const arr = currentWords.map(word => word.userVocabularyId === userVocabularyId ? {
        ...word,
        loading: true
    } : {...word});

    return arr;
}

const GERMAN_GENDER_ARTICLES = {'m': "der", 'f': "die", 'n': "das"};
const SPANISH_GENDER_ARTICLES = {'m': "el", 'f': "la", 'n': "el"};


function mapWordGender(learningLanguage: Language | null, str: string): string {
    if (learningLanguage === null || str.length === 0) return '';
    const gender: string = str[0];

    if (learningLanguage === "GERMAN") {
        // @ts-ignore
        return GERMAN_GENDER_ARTICLES[gender] ?? "";
    } else if (learningLanguage === "SPANISH") {
        // @ts-ignore
        return SPANISH_GENDER_ARTICLES[gender] ?? "";
    }

    return '';
}

export const vocabularySlice = createSlice({
    name: 'vocabulary',
    initialState: VOCABULARY_INITIAL_STATE,
    reducers: {
        addAlreadyCreated(state, action) {
            state.currentVocabularyWords = addAlreadyCreatedWord(state.currentVocabularyWords,
                action.payload.wordListWord, action.payload.vocabularyId, action.payload.vocabularyGroupId);
        },
        addWord(state, action) {
            state.currentVocabularyWords = addNewWord(state.currentVocabularyWords, action.payload);
        },
        removeWord(state, action) {
            state.currentVocabularyWords = removeWords(state.currentVocabularyWords, action.payload);
        },
        setCurrentlySelectedVocabulary(state, action) {
            state.selected = VOCABULARY_INITIAL_STATE.selected;
            state.selected = action.payload;
        },
        setCurrentWords(state, action) {
            state.currentVocabularyWords = setCurrentWordsWithTempId(action.payload, state.currentVocabularyWords);
        },
        setVocabularies(state, action) {
            state.vocabularies = filterVocabularies(action.payload);
        },
        setCurrentlyInspectedWord(state, action) {
            state.currentlyInspectedWord = action.payload;
        },
        setCurrentlyInspectedWordGender(state, action) {

            state.currentlyInspectedWordGender = mapWordGender(state.selected.learningLanguage, action.payload)
        },
        createVocabulary(state, action) {
            state.vocabularies = addVocabulary(state.vocabularies, action.payload);
        },
        updateTempIdWord(state, action) {
            state.currentVocabularyWords = replaceTempIdWord(action.payload, state.currentVocabularyWords)
        },
        removeVocabulary(state, action) {
            state.vocabularies = removeVocabularyFromState(action.payload, state.vocabularies);
        },
        createVocabularyGroupR(state, action) {
            state.selected = addVocabularyGroup(action.payload, state.selected);
        },
        deleteVocabularyGroupSlice(state, action) {
            const newVoc = removeVocabularyGroup(action.payload, state.selected);
            state.selected = newVoc;
            state.vocabularies = state.vocabularies.map(voc => voc.id === newVoc.id ? newVoc : voc);
        },
        resetWords(state) {
            state.allWords = VOCABULARY_INITIAL_STATE.allWords;
            state.currentVocabularyWords = VOCABULARY_INITIAL_STATE.currentVocabularyWords;
            state.currentlyInspectedWord = VOCABULARY_INITIAL_STATE.currentlyInspectedWord;
            state.selected = VOCABULARY_INITIAL_STATE.selected;
            state.suggestedVocabularyGroups = VOCABULARY_INITIAL_STATE.suggestedVocabularyGroups;
            state.vocabularyGroup = VOCABULARY_INITIAL_STATE.vocabularyGroup;
        },
        setSuggestedVocabularyGroups(state, action) {
            if (action.type !== "REHYDRATION") {
                state.suggestedVocabularyGroups = action.payload;
            }
        },
        removeSuggestedVocabularyGroup(state, action) {
            state.suggestedVocabularyGroups = removeFromSuggestedVocabularyGroups(state.suggestedVocabularyGroups, action.payload);
        },
        setVocabularyGroup(state, action) {
            state.vocabularyGroup = action.payload;
        },
        changeWordParameters(state, action) {
            state.currentVocabularyWords = setCurrentWordsWordWithId(action.payload.previousUserVocabularyId, action.payload.newUserVocabulary, state.currentVocabularyWords);
        },
        setWordLoading(state, action) {
            state.currentVocabularyWords = setWordLoadingById(state.currentVocabularyWords, action.payload.userVocabularyId);
        },
    }
})

export const {
    addAlreadyCreated,
    addWord,
    removeWord,
    setCurrentlySelectedVocabulary,
    setCurrentWords,
    setVocabularies,
    createVocabulary,
    setCurrentlyInspectedWord,
    updateTempIdWord,
    removeVocabulary,
    resetWords,
    createVocabularyGroupR,
    deleteVocabularyGroupSlice,
    setSuggestedVocabularyGroups,
    removeSuggestedVocabularyGroup,
    setVocabularyGroup,
    changeWordParameters,
    setWordLoading,
    setCurrentlyInspectedWordGender

} = vocabularySlice.actions;

export const vocabularyReducer = vocabularySlice.reducer;
