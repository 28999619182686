import {motion} from "framer-motion";

function ErrorAuthorization({children, open, setOpen}) {
    const closeError = () => setOpen(false);

    return (
        open && (
            <motion.div
                aria-hidden={!open}
                initial={{opacity: 0}}
                transition={{duration: 0.1}}
                animate={{opacity: 1}}
                className="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center backdrop-blur-md bg-opacity-50"
            >
                <div
                    className="bg-[#EBF0FB] w-[12rem] lg:w-[18rem] flex items-center justify-center p-4 rounded-lg shadow-xl"
                >
                    <div className="flex flex-col gap-8 p-2">
                        <div className="flex flex-col gap-4">
                            <h1 className="text-center text-3xl font-bold">Error</h1>
                            <p className="font-semibold text-xl">{children}</p>
                        </div>
                        <button
                            onClick={closeError}
                            className="p-2 bg-[#3C72FF] w-full rounded-xl hover:bg-[#3C72FF]/90 transition-all duration-200"
                        >
                            <p className="text-white font-semibold text-xl">Close</p>
                        </button>
                    </div>
                </div>
            </motion.div>
        )
    );

}

export function ErrorText({styleClass, children}) {
    return (
        <p className={`text-center text-error ${styleClass}`}>{children}</p>
    )
}

export default ErrorAuthorization;
