import axios, {AxiosRequestConfig} from 'axios';
import {v4 as uuid} from 'uuid';
import {LOCAL} from "../i18nf/constants";
import {BASE_URL} from '../constant';


export const check400 = (status: number) => {
    return Math.floor(status / 100) === 4
}

export const axios_get = async (url: string, config: {} = {}) => {
    try {
        const response = await axios.get(url, config);

        return response.data;
    } catch (err) {

        console.log("_________________________________");
        console.error("error performing request to url: " + url);
        console.error(err);
        console.log("_________________________________");

        // throw err;
        return null;
    }
}

export const axios_put = async (url: string, data: {} | [], config: {} = {}) => {
    try {
        const response = await axios.put(url, data, config);

        return response.data;
    } catch (err) {

        console.log("_________________________________");
        console.error("error performing request to url: " + url);
        console.error(err);
        console.log("_________________________________");

        // throw err;
        return null;
    }
}

export const fetch_get = async (url: string, token?: string, config: {} = {}) => {
    return (await fetch(
        `${BASE_URL}${url}`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            ...config
        }
    )).json();
}

export const fetch_pos = async (url: string, data: {} | [], headers?: {}) => {
    const response = await fetch(`${BASE_URL}${url}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify(data),
    })

    if (check400(response.status)) {
        window.location.href = "/login"

        throw new Error(response.statusText)
    }

    return response.json();
}

export const axios_post = async (url: string, data: {} | [], config?: AxiosRequestConfig<{} | []>) => {
    const response = await axios.post(url, data, config ?? {});

    if (check400(response.status) || response.data.error) {
        window.location.href = "/login"

        throw new Error(response.statusText + response.data.error || '')
    }

    return response.data;
}

export const axios_delete = async (url: string) => {
    const response = await axios.delete(url);

    if (check400(response.status)) {
        window.location.href = "/login"
        throw new Error(response.statusText)
    }

    return response.data;
}

export const getHttpHeaders = (token: string, contentType: string = "application/json") => {
    return {
        "Authorization": "Bearer " + token,
        "Content-Type": contentType
    }
}

export const getRandomUUID = (): string => {
    return uuid();
}

export const getBlockWidth = (word: string): string => {
    let width;
    if (word.length >= 7) width = word.length + 1;
    else width = word.length + 2;

    return width + 'ch';
}

export const shuffleArray = (array: any[]) => {
    for (let i = 0; i < array.length; i++) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const getFullLanguageNameForRequest = (shortened: string) => {
    switch (shortened.toLocaleLowerCase()) {
        case LOCAL.DE:
            return 'GERMAN';
        case LOCAL.EN:
            return 'ENGLISH';
        case LOCAL.ES:
            return 'SPANISH';
        default:
            return "ENGLISH";
    }
}

export const getFullLanguageName = (shortened: string) => {
    switch (shortened.toLocaleLowerCase()) {
        case LOCAL.DE:
            return 'Deutsch';
        case LOCAL.EN:
            return 'English';
        case LOCAL.ES:
            return 'Español';
        default:
            return "English";
    }
}

export const getShortLanguageName = (full: string) => {
    switch (full.toLocaleLowerCase()) {
        case "english":
            return "en";
        case 'spanish':
            return "es";
        case 'german':
            return 'de';
        default:
            return full;
    }
}

export const extractDate = (utc: number) => {
    const date = new Date(utc).toISOString();
    return date.substring(0, date.indexOf('T'))
}

export const capitalize = (word: string, index: number = 0) => {
    return word.charAt(index).toUpperCase() + word.slice(index + 1);
}
