import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as HintIcon} from '../../../assets/hint.svg';
import {selectFinalSequenceIndex, selectFinalTrainingSequence} from "../../../store/training/trainigSelector";
import {clearTrainingCache, incrementFinalSequenceIndex} from "../../../store/training/trainingSlice";
import TitleCard from "../../Cards/TitleCard";
import {useTranslation} from "react-i18next";
import TrainingClosePopup from "../TrainingClosePopup";
import {motion} from "framer-motion";
import {ReactComponent as Language} from '../../../assets/academic.svg';
import SpecialCharactersBlock from "../../../features/vocabulary/components/SpecialCharactersBlock";

type TrainingBlockProps = {
    title?: string;
    children: string | string[] | JSX.Element | JSX.Element[];
    showHint?: () => void;
    isHint?: boolean;
    showCorrectAnswer: () => void;
    isSkipped: boolean | undefined;
    isCorrect: boolean;
    handleSkipClick: () => void;
    addChar: (char: string) => void;
}
const BackButton = ({onBackClick, className}: { onBackClick: () => void, className: string }) => {
    const [slidingForm, setSlidingForm] = React.useState(false);

    const click = () => {
        setSlidingForm(true)
    }

    return (
        <>
            <button className={`flex items-start justify-center mt-1  pl-5 left-0 z-50 relative ${className}`}
                    onClick={click}>
                <CloseIcon
                    className="!w-6 !h-6 sm:!w-9 absolute sm:!h-9 hover:bg-gray-100 image-full p-0 md:p-[4px] text-color-big-text rounded-full duration-300 ease-out transition"
                />
            </button>
            {slidingForm &&
                <TrainingClosePopup setSlidingForm={setSlidingForm} onBackClick={onBackClick}/>
            }

        </>
    )
}


const TrainingBlock = ({
                           title,
                           children,
                           showHint,
                           showCorrectAnswer,
                           isSkipped,
                           isHint,
                           isCorrect,
                           handleSkipClick,
                           addChar
                       }: TrainingBlockProps) => {
    const dispatch = useDispatch();

    const skipButtonRef = useRef<HTMLButtonElement>(null);
    const hintButtonRef = useRef<HTMLButtonElement>(null);
    const finalTrainingSequence = useSelector(selectFinalTrainingSequence)
    const trainingSequenceIndex = useSelector(selectFinalSequenceIndex);
    const progress = trainingSequenceIndex * 100 / finalTrainingSequence.length;
    const continueButtonRef = useRef<HTMLButtonElement>(null);
    const {t} = useTranslation()
    const [hintIcon, setHintIcon] = React.useState(false);
    const ref = useRef<HTMLDivElement>(null);


    const onBackClick = () => {
        dispatch(clearTrainingCache());
    }

    const handleCharacterClick = (char: string) => {
        addChar(char)
        setHintIcon(false)
    }

    useEffect(() => {
        if (skipButtonRef.current) {
            if (isSkipped || isCorrect) {
                skipButtonRef.current.disabled = true;
            }
            skipButtonRef.current.disabled = true;
        }
    }, [isCorrect, isSkipped]);

    useEffect(() => {
        if (hintButtonRef.current) {
            if (isCorrect || isSkipped || isHint) {
                hintButtonRef.current.disabled = true;
            }
        }
    }, [isCorrect, isHint, isSkipped]);

    useEffect(() => {
        if (continueButtonRef.current) {
            if (isSkipped) {
                continueButtonRef.current.disabled = true;
                setTimeout(() => {
                    if (continueButtonRef.current) continueButtonRef.current.disabled = false;
                    console.log("🚀 ~ continueButtonRef.current:", continueButtonRef.current)
                    console.log("🚀 ~ continueButtonRef.current:", continueButtonRef.current?.disabled)
                }, 1000)
            } else {
                continueButtonRef.current.disabled = true;
            }
        }
    }, [isSkipped])

    const handleHintClick = () => {
        if (!isHint) {
            showHint && showHint();
        }
    }


    const handleSkipClicks = () => {
        showCorrectAnswer();
        dispatch(incrementFinalSequenceIndex());
    }


    const handleMouseLeave = (event: any, setClickIcon: any) => {
        if (ref.current && ref.current.contains(event.target)) {
            setClickIcon(false);
        }
    }


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current) {
                if (!ref.current.contains(event.target)) {
                    setHintIcon(false);
                }
                if (ref.current.contains(event.target)) {
                    setHintIcon(true);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);

    const skipButtonClassName = "btn-all btn-md cursor-pointer text-center p-3 w-64 rounded " +
        "hover:text-white bg-button-color my-4 hover:bg-button-color"

    return (
        <div className="w-full flex flex-col h-auto md:h-full">
            <div className="flex flex-col justify-between items-center">
                <div
                    className="relative flex flex-row items-сenter  justify-between w-full mx-auto my-0 max-w-[1084px] max-lg:mt-5 max-md:mx-0">
                    <BackButton onBackClick={onBackClick} className=""/>
                    <LinearProgressWithLabel value={progress}/>
                    <div className="flex relative">

                        <div className="">
                            <div className="w-6 h-6 sm:w-7 sm:h-7">
                                <Language
                                    onMouseEnter={() => setHintIcon(true)}
                                    onMouseLeave={() => handleMouseLeave(ref, setHintIcon)}
                                    className="!w-6 !h-6 sm:!w-7 sm:!h-7 max-lg:mt-1
                                    cursor-pointer absolute xl:relative sm:top-[10px] top-[0px]
                                    right-[2rem] xl:right-[0.5rem]"/>
                            </div>

                            {hintIcon &&
                                <div ref={ref} className="animation transition-all duration-300 ease-out z-50 ">
                                    <SpecialCharactersBlock onCharacterClick={handleCharacterClick}/>
                                </div>
                            }

                        </div>
                        <button
                            style={{pointerEvents: isCorrect || isSkipped || isHint ? "none" : "all"}}
                            className="relative flex items-start justify-center z-10"
                            onClick={handleHintClick}
                        >
                            {showHint && <HintIcon
                                className="w-6 h-6 max-lg:mt-1
                                    cursor-pointer absolute xl:relative sm:top-[10px] top-[0px] right-[5px]
                                    hover:fill-yellow-300 duration-300 ease-out transition sm:w-7 sm:h-7"/>
                            }
                        </button>
                    </div>

                </div>
                <TitleCard title={title}>
                    <div
                        className="flex min-w-full px-4 w-full  min-h-full justify-start md:mb-10 my-10 md:justify-center items-center flex-wrap flex-col gap-0 lg:gap-4">
                        {children}
                    </div>
                </TitleCard>
            </div>
            <div className="">
                <div className="flex justify-center flex-grow-0">  {!isSkipped ? (
                    <motion.button
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        style={{pointerEvents: isCorrect ? "none" : "all"}}
                        onClick={handleSkipClick}
                        onChange={handleSkipClicks}
                        className={`btn-all btn-md cursor-pointer text-center p-3 w-64 rounded 
                        hover:text-white bg-button-color my-4 hover:bg-button-color`}
                    >
                        <span className="text-white font-bold text-xl">{t('Skip')}</span>
                    </motion.button>
                ) : (
                    <motion.button
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        style={{pointerEvents: !isSkipped ? "none" : "all"}}
                        onClick={handleSkipClick}
                        onChange={handleSkipClicks}
                        className={skipButtonClassName}
                    >
                        <button ref={continueButtonRef} disabled={!isSkipped}>
                            <p className="text-white font-bold text-xl">{t('Continue')}</p>
                        </button>
                    </motion.button>
                )}
                </div>
            </div>
        </div>
    );
}
export default TrainingBlock;

export const LinearProgressWithLabel: React.FC<{ value: number }> = ({value}) => {
    return (
        <div className="loaders w-[30%] max-md:w-[50%] mt-1 lg:mt-6">
            <div className="progress-bars correct" style={{width: `${value}%`}}></div>
        </div>
    );
};

export const LinearProgress: React.FC<{ value: number }> = ({value}) => {
    return (
        <div className="loaders w-[30%] max-md:w-[50%] mt-1 lg:mt-6">
            <div className="progress-bar correct" style={{width: `${value}%`}}></div>
        </div>
    );
};
