import {ChangeEvent, forwardRef, InputHTMLAttributes} from 'react';

export type TrainingInputProps = {
    correctValue: string;
    width: string;
    value?: string;
    trainingExampleId: number;
    defaultValue?: string;
    onChangeEvent: (e: ChangeEvent<HTMLInputElement>) => void;
    correct: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const TrainingInput = forwardRef<HTMLInputElement, TrainingInputProps>(({
                                                                            width,
                                                                            value,
                                                                            trainingExampleId,
                                                                            defaultValue,
                                                                            onChangeEvent,
                                                                            correct,
                                                                            correctValue,
                                                                            ...rest
                                                                        }, ref) => {
    const className = `training-input my-3 ml-2  
    ${correct ? "bg-green-50 border-2 border-green-400"
        : "bg-slate-50 border-2 border-blue-200"}`;


    return (
        <input
            tabIndex={0}
            autoFocus
            readOnly={correct}
            style={{width: width}}
            ref={ref}
            value={value ?? defaultValue}
            key={trainingExampleId}
            onChange={onChangeEvent}
            autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="off"
            className={className}
            {...rest}
        />
    )
});

export default TrainingInput
