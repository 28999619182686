export const POSSIBLE_TRANSLATIONS_WORD_LIMIT = 8;
export const SOUND_TRAINING =
    "https://fluentfusion-static.s3.eu-central-1.amazonaws.com/training_end_sound.mp3";

export const [BASE_URL, SOCKET_URL] = get();

function get() {
    if (process.env.NODE_ENV === 'production') {
        return ["https://api.fluent-fusion.com", "wss://api.fluent-fusion.com/wss/"];
    } else {
        // return ["https://api.fluent-fusion.com", "wss://api.fluent-fusion.com/wss/"];
        return ["http://localhost:9090", "ws://localhost:1234"];
    }
}
