import React, {ReactNode, useState} from "react";
import {ReactComponent as UKFlag} from "../../assets/flag-united-kingdom.svg"
import {ReactComponent as SpainFlag} from "../../assets/flag-spain.svg"
import {ReactComponent as GermanFlag} from "../../assets/flag-germany.svg"

import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import penguinSkates from "../../assets/penguin_skates.gif"
import {motion} from "framer-motion";
import StartLearning from "../../pages/protected/StartLearning";
import england from "../../assets/england.png"
import germany from "../../assets/german.png"
import spain from "../../assets/spain.png"


export interface LanguagesProps {
	value: string
	label: string
	img: string
	flag: JSX.Element;

}
export const LanguageButton = ({sectionName, icon, t, onClick}: {
    sectionName: string,
    icon: ReactNode,
    t: TFunction<"translation", null>,
	onClick: () => void
}) => {

    return (
        <div
	        onClick={onClick}
            className="btn-flag hover:bg-blue-50 transition-all duration-100">
            <div className="flex items-center gap-1">
                {icon}
                <h2 className="text-lg font-semibold">{t(sectionName)}</h2>
            </div>

        </div>
    )
}
const SectionLanguage = () => {
    const {t} = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

	const languages : LanguagesProps[]  = [
		{value: "English", label: "English is the global language of communication, spoken by over 1.5 billion people worldwide. It’s essential for international careers, travel, and accessing education at top universities." +
				"From Hollywood movies to the latest innovations in tech, " +
				"English connects you to global culture and opportunities, " +
				"making it an indispensable skill in today’s world.", flag: <UKFlag width="3.3rem" height="3.3rem" />, img: england},
		{value: "Spanish", label: "Spanish is a vibrant language spoken by over 500 million people across 20+ countries, including Spain, Mexico, and Argentina. " +
				"It opens doors to a diverse world of culture, from flamenco and salsa to incredible cuisines and festivals. " +
				"Spanish is also vital for business in Latin America and the U.S., making it a key language for both personal and professional connections.", flag: <SpainFlag className="" width="3.3rem" height="3.3rem" />, img: spain},
		{value: "German", label: "German is the most widely spoken native language in Europe and a gateway to its rich culture and innovation." +
				" It’s the language of Germany, Austria, and Switzerland—countries known for their contributions to science, engineering, and the arts." +
				" Whether you’re pursuing a career in global industries or exploring Europe’s heritage, German is a valuable asset that connects you to countless opportunities.", flag: <GermanFlag className="" width="3.3rem" height="3.3rem" />, img: germany},
	]

	const languagesButton   = [
		{value: "English", flag: <UKFlag />},
		{value: "Spanish", flag: <SpainFlag />},
		{value: "German", flag: <GermanFlag />},
	]


	const openPopup = (word: string) => {
		setSelectedLanguage(word)
	}

	const closePopup = () => {
		setSelectedLanguage(null)
	}



	return (
		<>
		<section className="bg-[#1F75FE] from-blue-800 via-blue-500 to-blue-800">
			<motion.div initial={{ opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }}
				className="max-w-[64rem] px-4 pt-0 pb-4 xl:p-0 mx-auto
					gap-0 sm:gap-0 lg:gap-24 flex justify-between items-center sm:items-start flex-col sm:flex-row">
				<div>
					<img src={penguinSkates} alt="Penguin"  className="mt-0 w-[80%] h-[100%] sm:w-[90%] lg:mt-6 max-w-[64rem] m-auto py-[18.3px] flex justify-between max-md:justify-around items-center " />
				</div>
				<div className="p-4 lg:p-0">
					<h3 className="font-bold mb-8 mt-4 text-4xl lg:text-5xl text-white ">{t("SectionLanguageMainText")} <span
						className="text-blue-100"> {t("SectionLanguageContinuedText")}</span></h3>
					<div  className="grid grid-cols-1 md:grid-cols-2">
						{languagesButton.map((e, index) =>
							<LanguageButton key={index} sectionName={e.value} icon={e.flag} t={t} onClick={() => openPopup(e.value)} />
						)}
					</div>
				</div>
			</motion.div>
		</section>
			{selectedLanguage && <StartLearning onClose={closePopup} languages={languages} open={!!selectedLanguage} selectedLanguage={selectedLanguage}  />}
		</>

	)
}

export default SectionLanguage