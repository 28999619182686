import {createSelector} from "reselect";
import {RootState} from "../../app/store";

const selectUserReducer = (state: RootState) => state.user;

export const selectToken = (state: RootState) => state.user.token

export const selectTheme = createSelector(
    [selectUserReducer],
    (user) => user.theme,
)

export const selectRoomCode = createSelector(
    [selectUserReducer],
    (state) => state.roomCode,
)

export const selectLastPickedVocabularyId = createSelector(
    [selectUserReducer],
    (state) => state.lastPickedVocabularyId,
)

export const selectUserDetails = createSelector(
    [selectUserReducer],
    (state) => state
)

export const selectCurrentStreak = createSelector(
    [selectUserReducer],
    (state) => state.currentStreak
)

export const selectMaxStreak = createSelector(
    [selectUserReducer],
    (state) => state.maxStreak
)


export const selectIsValid = (state: RootState) => state.user.isValid;
