import {ChangeEvent, FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {BASE_URL} from "../../constant";
import {showNotification} from "../../store/headerSlice";
import {check400} from "../../utils/globalUtils";
import PasswordInput from "./PasswordInput";
import UnauthorizedFormComponent from "./UnauthorizedFormComponent";
import ErrorText from "../../components/Typography/ErrorText";
import penguin from "../../assets/penguin_biorthday.gif";


function NewPassword() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useParams();
    const [open, setOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>("");
    const {t} = useTranslation();


    async function submitForm(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoading(true);
        const res = await fetch(`${BASE_URL}/auth/forgotPassword/${token}?newPassword=${newPassword}`);
        setLoading(false);

        if (check400(res.status)) {
            setErrorMessage(t('PasswordChangedError'));
            setOpen(true);
            return;
        }
        dispatch(showNotification({
            status: 1,
            message: t('PasswordChanged')
        }))
        setTimeout(() => {
            navigate('/login')
        }, 750)
    }

    return (
        <>
            <ErrorText open={open} setOpen={setOpen}>{errorMessage}</ErrorText>
            <UnauthorizedFormComponent>
                <div className='py-2 px-4 lg:py-24 lg:px-10 bg-white rounded-lg'>
                    <form onSubmit={submitForm}
                          className="flex flex-col justify-between w-[90%] lg:w-[75%] mx-auto h-[70%]  ">
                        <img src={penguin} alt=""
                             className="w-[100%] sm:w-[70%] py-8 lg:py-0 h-[60%] object-contain mx-auto"/>
                        <div className="my-2">
                            <PasswordInput
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}/>
                        </div>
                        <button type="submit"
                                className={"py-2 px-4 text-md lg:text-lg font-medium text-white w-[50%] bg-button-color hover:bg-[#3C72FF] transition duration-300  mt-2 rounded-xl mx-auto flex justify-center items-center" + (loading ? " loading" : "")}>{t('ResetPassword')}
                        </button>
                    </form>
                </div>
            </UnauthorizedFormComponent>
        </>
    )
}

export default NewPassword;