import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {SOUND_TRAINING} from "../../../../constant";
import {incrementFinalSequenceIndex, incrementHintsNumber} from "../../../../store/training/trainingSlice";
import {getBlockWidth} from "../../../../utils/globalUtils";
import {
    addToCurrentCursorPosition,
    hintTriggerEvent,
    isCorrect,
    playSound,
    revealAnswer
} from "../../../../utils/trainingUtils";
import {TrainingTypesProps} from "../../Training";
import TrainingBlock from "../TrainingBlock";
import TrainingImage from "../TrainingImage";
import TrainingInput from "./TrainingInput";
import {motion} from "framer-motion";
import {Language} from "../../../../store/languageSlice";

const TranslationTraining = ({targetWord, elem}: TrainingTypesProps) => {
    const dispatch = useDispatch();
    const [input, setInput] = useState<string>("");
    const [backupInput, setBackupInput] = useState<string | undefined>("");
    const [correct, setCorrect] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);
    const wordFrom = targetWord.wordTranslation.wordFrom;

    const inputRef = React.useRef<HTMLInputElement>(null);

    const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
    }

    const completeTraining = (localSkipped: boolean) => {
        setCorrect(true)

        playSound(SOUND_TRAINING)
            .then(message => console.log(message))
            .catch(error => console.error("Error:", error));

        playSound(targetWord.wordTranslation.wordFrom.soundUrl)
            .then(message => console.log(message))
            .catch(error => console.error("Error:", error));

        if (!localSkipped) {
            console.log('auto-sip')
            setBackupInput(elem.trainingExample.identifiedWord)
            setInput("")
            setTimeout(() => {
               resetState()
            }, 1000)
        }
    }

    const resetState = useCallback((incrementFinalSequence: boolean = true) => {
        setInput("")
        setBackupInput("")
        setCorrect(false)
        setSkipped(false)
        if (incrementFinalSequence) {
            dispatch(incrementFinalSequenceIndex())
        }

    }, [dispatch])


    const showCorrectAnswer = () => {
        setCorrect(true);
        revealAnswer(setInput, elem.trainingExample.identifiedWord, elem)
        completeTraining(true)
        setSkipped(true)
    }

    const showHint = () => {
        hintTriggerEvent(setInput, elem.trainingExample.identifiedWord, inputRef)
        dispatch(incrementHintsNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
    }

    const handleSkipClick = () => {
        if (correct) {
            resetState()
        } else {
            showCorrectAnswer()
        }
    }

    useEffect(() => {
        if (isCorrect(input || "", elem.trainingExample.identifiedWord, wordFrom.language as Language)) {
            completeTraining(skipped || false)
        }
    }, [completeTraining, elem.trainingExample.identifiedWord, input, skipped, wordFrom.language]);


    useEffect(() => {
        resetState(false)
        inputRef?.current?.focus();
    }, [resetState]);

    return (

        <>
            <TrainingBlock showCorrectAnswer={showCorrectAnswer} isSkipped={skipped} isCorrect={correct}
                           addChar={(c) => addToCurrentCursorPosition(setInput, c, inputRef)}
                           handleSkipClick={handleSkipClick} showHint={showHint}>

                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}
                            transition={{duration: 0.5}}
                            className={`flex flex-col items-center`}>
                    <TrainingImage src={targetWord.wordTranslation.wordFrom.imageUrl}/>
                    <span className={`text-2xl text-center mt-4`}>
                        {targetWord.wordTranslation.wordTo.word}
                    </span>
                    <TrainingInput
                        ref={inputRef}
                        key={`${elem.trainingExample.id}${elem.trainingExample.identifiedWord}${elem.trainingExample.sentence}`}
                        width={getBlockWidth(elem.trainingExample.identifiedWord)}
                        onChangeEvent={onChangeEvent}
                        value={!input || input.length === 0 ? backupInput : input}
                        correct={correct ?? false}
                        trainingExampleId={elem.trainingExample.id}
                        correctValue={elem.trainingExample.identifiedWord}
                    />
                </motion.div>

            </TrainingBlock>
        </>
    )
}

export default TranslationTraining
